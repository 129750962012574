<template>
  <div 
    v-if="isMobile == true && showAutoplay == true"
    class="autoplay"
    :style="`${height}${fullScreenClass}`"
    data-anima="left"
  >
    <div 
      :style="`${height}${thumbCourse}`"
    ></div>
    <div 
      class="icon-x" 
      @click="cancelAutoplay"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
      </svg>
    </div>
    <div class="info-class">
      <div class="right-autoplay">
        <div class="title-class">
          <span>A seguir</span>
          <span style="font-weight: 600;">{{ nextLesson.title }}</span>
        </div>
      </div>
      <div class="left-autoplay">
        <div 
          data-anima="left" 
          class="button-next button-autoplay"
          @click="sendToNextLesson()"
        >
          <span>
            Próximo
          </span>
        </div>
      </div>
    </div>
    <div class="autoplay-countdown">
      <p>{{ count }}</p>
    </div>
  </div>
  <div v-else-if="isMobile == false && showAutoplay == true"
    :style="`${height}${fullScreenClass}`"
    class="autoplay"
    :class="isFullscreenData == true ? 'fullscreen' : ''"
    data-anima="left"
  >
    <div
     :style="`${height}${fullScreenClass}${thumbCourse}`"
    ></div>
    <div class="info-class">
      <div class="right-autoplay">
        <div class="container-img">
          <img :src="thumbNextLesson">
        </div>
        <div class="title-class">
          <span>A seguir</span>
          <span style="font-weight: 600;">{{ nextLesson.title }}</span>
        </div>
      </div>
      <div class="left-autoplay">
        <div 
          data-anima="left" 
          class="button-cancel button-autoplay"
          @click="cancelAutoplay"
        >
          <span>
            Cancelar
          </span>
        </div>
        <div 
          data-anima="left" 
          class="button-next button-autoplay"
          @click="sendToNextLesson()"
        >
          <span>
            Próximo
          </span>
        </div>
      </div>
    </div>
    <div class="autoplay-countdown">
      <p>{{ count }}</p>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props:['thumbLesson', 'thumbNextLesson', 'isMobile', 'currentLesson', 'nextLesson', 'isFullScreen'],
  data() {
    return {
      count: 5,
      countdown: null,
      showAutoplay: true,
      isFullscreenData: false
    }
  },
  methods: {
    ...mapActions([
      "sendToNextLesson", 
      "setIsAutoplay",
      "markLesson",
    ]),
    countStart() {
      this.countdown = setInterval(() => {
        if (this.count > 0) {
          this.count--; 
        } else if (this.count === 0){
          document.querySelector('.button-next').click()
        }
      }, 1000);
    },
    cancelAutoplay() {
      this.setIsAutoplay(false)
      this.showAutoplay = false;
      if(this.currentLesson.is_completed == false){
        this.markLesson(this.currentLesson)
      }
      this.$emit('cancelAutoplay');
    },
    verifyFullscreen(){
      if (this.isFullscreenData) {
        document.body.classList.add('fullscreen-mode');
        document.body.style.overflow = 'hidden';
      } else {
        document.body.classList.remove('fullscreen-mode');
        document.body.style.overflow = '';
      }
    },
  },
  computed: {
    height() {
      if (this.isFullscreenData) {
        return '';
      }
      if (this.isMobile == true) {
        return 'min-height: 250px; max-height: 250px; width: 100%;'
      }
      return 'min-height: 530px; max-height: 530px; width: 100%; max-width: 944px;'
      
    },
    fullScreenClass() {
      if (this.isFullscreenData) {
        return 'position: fixed; top:0; left:0; right:0; bottom: 0; height: 100vh; z-index: 1; overflow: hidden;'
      }
      return ''
    },
    thumbCourse(){
      return `backgroundImage: url('${this.thumbLesson}'); backgroundSize: cover; backgroundPosition: center; backgroundRepeat: no-repeat; height: 100%; filter: blur(5px)`
    },
  },
  mounted() {
    this.isFullscreenData = this.isFullScreen
    this.setIsAutoplay(true)
    this.verifyFullscreen()
    this.countStart();
  },
  beforeDestroy(){
    clearInterval(this.countdown); 
  }
}
</script>
<style scoped lang="scss">
.info-class {
  position: absolute;
  bottom: 0;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 944px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  .right-autoplay{
    display: flex;
    gap: 20px;
    color: white;
    .container-img{
      object-fit: cover;
      img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        object-fit: cover
      }
    }
    .title-class{
      display: grid;
      align-items: center;
      max-width: 300px;
      span {
        white-space: nowrap;    
        overflow: hidden;        
        text-overflow: ellipsis; 
      }
    }
  }
  .left-autoplay{
    display: flex;
    gap: 15px;
    .button-autoplay{
      padding: 10px 25px;
      border-radius: 20px;
      font-weight: 500;
      cursor: pointer;
    }
    .button-cancel{
      background-color: rgba(255, 255, 255, 0.25);
      color: white;
      font-weight: 600
    }
    .button-next{
      background-color: white;
      color: #16171A;
      font-weight: 600;
    }
  }
}
.autoplay-countdown{
  display: grid;
  height: 110px;
  width: 110px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  p {
    font-size: 40px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-weight: 700;
  }
}
.icon-x {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100%;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 12%;
  left: 94%;
  transform: translate(-50%, -50%); 
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 20px;
    height: 20px; 
    fill: white;
  }
}
.fullscreen {
  .info-class{
    z-index: 2; 
    width: 100vw; 
    max-width: 100vw; 
    height: 15%;
  }
  .container-img{
    img {
      width: 80px !important; 
      height: 80px !important;
    }
  }
  .left{
    gap: 25px;
  }
  .autoplay-countdown{
    z-index: 2;
  }
}
@media (max-width: 720px) {
  .info-class{
    height: 25%;
  }
  .autoplay-countdown{
    top: 45%;
    height: 90px;
    width: 90px;
    p {
      font-size: 32px;
    }
  }
}
</style>